import axios from "axios";
import type {
  MembershipListRes,
  MembershipLevelItem,
  MembershipDiscountPacksRes,
  MembershipDiscountPack,
  MembershipPurchasedDiscountPacksRes,
  MembershipUserLevelInfo,
  MembershipUserStatisticsRes,
} from "./types/membership";
import type { PagesRequest } from "api/types/common";

export const getMembershipList = async () =>
  axios.get<MembershipListRes>("/membership/level/list");

export const getMembershipItem = async (id: number) =>
  axios.get<MembershipLevelItem>(`/membership/level/${id}`);

export const getMembershipDiscountPacks = async () =>
  axios.get<MembershipDiscountPacksRes>("/membership/discount-pack/list", {
    params: {
      page: 0,
      itemsPerPage: 20,
    },
  });

export const getMembershipDiscountPackItem = async (id: number) =>
  axios.get<MembershipDiscountPack>(`/membership/discount-pack/${id}`);

export const purchaseMembershipDiscountPack = async (id: number) =>
  axios.post<boolean>("/membership/discount-pack/purchase", {
    membershipDiscountPackId: id,
  });

export const getPurchasedMembershipDiscountPacks = async (
  reqData: PagesRequest
) =>
  axios.get<MembershipPurchasedDiscountPacksRes>(
    "/membership/discount-pack/purchase/list",
    {
      params: {
        ...reqData,
      },
    }
  );

export const getUserMembershipLevel = async () =>
  axios.get<MembershipUserLevelInfo>("/membership/user/level");

export const getUserMembershipReferralStatistics = async (
  reqData: PagesRequest
) =>
  axios.get<MembershipUserStatisticsRes>(
    "/membership/user/referral/statistics",
    {
      params: {
        ...reqData,
      },
    }
  );

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import WithdrawInfoRows from "./WithdrawInfoRows";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
} from "../styled";
import type { CurrencyInterface } from "helpers/types";
import type { FntClaimInfo } from "api/types/fntClaim";

import stakeImg from "assets/images/stake_success.jpg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface SuccessStakeStepProps {
  onClose: () => void;
  claimInfo: FntClaimInfo | null;
  currencies: CurrencyInterface[] | null;
}

const SuccessStakeStep: FC<SuccessStakeStepProps> = ({
  onClose,
  claimInfo,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={stakeImg} alt="decorative" />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_600}
              lineHeight={30}
            >
              {claimInfo?.currencyName}
            </AppText>
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {t("WITHDRAW")} {t("CONFIRMED")}
            </AppText>
            <StatusInfoLine>
              {claimInfo && currencyIcons[claimInfo.currencyName]
                ? currencyIcons[claimInfo.currencyName]
                : currencyIcons.not_found}
              <AppText
                fontSize={20}
                fontWeight={600}
                color={colors.gray_700}
                lineHeight={30}
              >
                {normolizeDecimalsWithNoCode(
                  claimInfo ? Number(claimInfo.amount) : 0,
                  claimInfo?.currencyName || "USDT",
                  currencies
                )}
              </AppText>
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {" "}
                {claimInfo?.currencyName}
              </AppText>
            </StatusInfoLine>
          </InfoColumn>

          <WithdrawInfoRows
            claimInfo={claimInfo}
          />

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessStakeStep;

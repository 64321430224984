import { FC, useState, useCallback, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react-lite";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import { purchaseMembershipDiscountPack } from "api/membership";
import ConfirmPurchaseStep from "./ConfirmPurchaseStep";
import SuccessPurchaseStep from "./SuccessPurchaseStep";
import { GlobalStore } from "stores";
import type { MembershipDiscountPack } from "api/types/membership";
import type {
  ModalStepsType,
  CurrencyInterface,
  WalletsListInterface,
} from "helpers/types";

interface CardPurchaseModalProps {
  getPurchasedMembershipPacks: () => void;
  isOpen: boolean;
  membershipPack: MembershipDiscountPack | null;
  onClose: () => void;
  currencies: CurrencyInterface[] | null;
  walletsList: WalletsListInterface[] | null;
  setMembershipPack: Dispatch<SetStateAction<MembershipDiscountPack | null>>;
  getUserMembershipInfo: () => void;
}

const CardPurchaseModal: FC<CardPurchaseModalProps> = ({
  isOpen,
  membershipPack,
  setMembershipPack,
  onClose,
  currencies,
  walletsList,
  getUserMembershipInfo,
  getPurchasedMembershipPacks,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ModalStepsType>("confirm");

  const onSubmit = () => {
    if (!membershipPack) {
      return;
    }

    setIsLoading(true);

    purchaseMembershipDiscountPack(membershipPack.id)
      .then(() => {
        getUserMembershipInfo();
        getPurchasedMembershipPacks();
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const closeModal = useCallback(() => {
    if (isLoading) {
      return;
    }
    onClose();
  }, [isLoading, onClose]);

  return (
    <BasicModal
      onExited={() => {
        setMembershipPack(null);
        setStep("confirm");
      }}
      isOpen={isOpen}
      onClose={closeModal}
      title="Purchase membership pack"
    >
      {isLoading && <Preloader isStatic />}

      {step === "confirm" && (
        <ConfirmPurchaseStep
          membershipPack={membershipPack}
          onClose={closeModal}
          onSubmit={onSubmit}
          currencies={currencies}
          walletsList={walletsList}
        />
      )}

      {step === "success" && (
        <SuccessPurchaseStep
          membershipPack={membershipPack}
          onClose={closeModal}
          currencies={currencies}
        />
      )}
    </BasicModal>
  );
};

export default observer(CardPurchaseModal);

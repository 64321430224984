import { FC, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import QRCode from "react-qr-code";
import { ReadInfo } from "components/Inputs";
import { AppText, VerificationWarning } from "components";
import {
  MainContainer,
  TransactionGroup,
  QrContainer,
  QrZone,
  WarningsContainer,
  PaddingContainer,
} from "../styled";
import { colors } from "helpers/consts";
import { ModalWarningInfo } from "components/Modals";
import DepositInfoRows from "./DepositInfoRows";
import type {
  WalletsListInterface,
  PricesInterface,
  CurrencyInterface,
  UserLevel,
} from "helpers/types";
import type { FoxpayGetBankAccRes } from "api/types/foxpay";

interface DepositMainTabProps {
  prices: PricesInterface | null;
  walletsList: WalletsListInterface[] | null;
  chosenNetwork: string;
  isLoading?: boolean;
  bankAccountDetails: FoxpayGetBankAccRes | null;
  paymentType?: CurrencyInterface;
  currentWallet?: WalletsListInterface;
  walletAddress?: string;
  level: UserLevel;
}

const DepositMainTab: FC<DepositMainTabProps> = ({
  prices,
  walletsList,
  chosenNetwork,
  isLoading = false,
  bankAccountDetails,
  paymentType,
  currentWallet,
  walletAddress,
  level,
}) => {
  const { t } = useTranslation();

  const walletMemo = useMemo<string | undefined | null>(() => {
    if (
      paymentType?.name &&
      currentWallet &&
      chosenNetwork &&
      paymentType?.useMemo
    ) {
      return currentWallet.addresses.find(
        ({ networkCode }) => networkCode === chosenNetwork
      )?.memo;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsList, paymentType, chosenNetwork, currentWallet]);

  return (
    <>
      {paymentType?.name === "EUR" && level !== "SENIOR" ? (
        <VerificationWarning
          text={`${paymentType.name} ${t("DEPOSIT_VERIFY_REQUIRED")}`}
        />
      ) : (
        <>
          <WarningsContainer>
            <ModalWarningInfo
              text={
                paymentType?.name !== "EUR" ? (
                  <Trans
                    i18nKey="CHECK_DEPOSIT_ADDRESS"
                    values={{
                      currency: paymentType?.name || "",
                      networkName:
                        chosenNetwork && paymentType?.networkName
                          ? ` ${paymentType?.networkName}`
                          : "",
                    }}
                  />
                ) : (
                  t("SENDING_FUNDS_CASH_INFO")
                )
              }
              subText={
                paymentType?.name !== "EUR"
                  ? t("SENDING_FUNDS_INFO")
                  : t("SENDING_CASH_FUNDS_INFO")
              }
            />

            <ModalWarningInfo text={t("PROCEED_INFO")} />
          </WarningsContainer>

          <MainContainer $padding="1.6rem 1rem">
            <TransactionGroup>
              <AppText color={colors.gray_500}>{t("DEPOSIT_ADDRESS")}</AppText>
              {paymentType?.name !== "EUR" ? (
                <>
                  {!paymentType?.useMemo && (
                    <QrContainer>
                      <QrZone $isBlured={!walletAddress}>
                        <QRCode
                          value={walletAddress || "placeholderQR"}
                          size={144}
                        />
                      </QrZone>
                    </QrContainer>
                  )}
                  {paymentType?.useMemo && walletMemo ? (
                    <>
                      <AppText
                        color={colors.gray_500}
                        style={{ marginTop: "2rem" }}
                      >
                        Memo
                      </AppText>
                      <ReadInfo text={walletMemo} withCopy />
                    </>
                  ) : null}
                  <AppText
                    color={colors.gray_500}
                    style={{ marginTop: "1rem" }}
                  >
                    Address
                  </AppText>
                  <ReadInfo
                    text={
                      walletAddress || "notRealWalletAddressJustSomeExample"
                    }
                    isBlured={!walletAddress}
                    withCopy
                  />
                </>
              ) : (
                <>
                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "PLACEHOLDERIBAN"
                          : bankAccountDetails?.iban || "-"
                      }
                      withCopy
                      info="IBAN"
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "PLACEHOLDERBIC"
                          : bankAccountDetails?.bic || "-"
                      }
                      withCopy
                      info="BIC/SWIFT"
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "Placeholder Address"
                          : `${
                              bankAccountDetails?.country
                                ? bankAccountDetails?.country + ", " || ""
                                : "-"
                            }${bankAccountDetails?.address || ""}`
                      }
                      withCopy
                      allowWrap
                      info={t("ADDRESS")}
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "Bank Name"
                          : bankAccountDetails?.bankName || "-"
                      }
                      withCopy
                      info={t("BANK")}
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "Bank Address"
                          : bankAccountDetails?.bankAddress || "-"
                      }
                      withCopy
                      info={t("BANK_ADDRESS")}
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <PaddingContainer>
                    <ReadInfo
                      text={
                        isLoading
                          ? "PLACEHOLDERRECIPIENT"
                          : bankAccountDetails?.name || "-"
                      }
                      withCopy
                      info={t("RECIPIENT")}
                      isBlured={isLoading}
                    />
                  </PaddingContainer>

                  <ModalWarningInfo
                    component={
                      <ReadInfo
                        text={
                          isLoading
                            ? "PLACEHOLDERCODE"
                            : bankAccountDetails?.code || "-"
                        }
                        withCopy
                        info={t("DETAILS")}
                        isBlured={isLoading}
                      />
                    }
                    padding="0.8rem 0.4rem"
                    text={t("DEPOSIT_WARNING")}
                  />
                </>
              )}
            </TransactionGroup>
          </MainContainer>

          <DepositInfoRows currency={paymentType} prices={prices} />
        </>
      )}

      {/* <SemiContainer>
                  <Checkbox
                    checked={isWarnConfirmed}
                    onClick={() => setIsWarnConfirmed(!isWarnConfirmed)}
                    label={t("CONFIRM_PROCEED_INFO")}
                    variant="warn"
                  />

                  <Tooltip
                    onClose={closeTooltip}
                    open={tooltipiIsOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={t("COPIED")}
                  >
                    <ModalButton
                      onClick={handleCopy}
                      disabled={!isWarnConfirmed}
                    >
                      {paymentType?.name !== "EUR"
                        ? t("COPY_DEPOSIT_ADDRESS")
                        : `${t("COPY")} IBAN`}
                    </ModalButton>
                  </Tooltip>
                </SemiContainer> */}
    </>
  );
};

export default DepositMainTab;

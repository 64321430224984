import {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colors, paybisAvailableCoins } from "helpers/consts";
import {
  CurrenciesStore,
  GlobalStore,
  UserStore,
  WalletStore,
  ThirdPartyStore,
} from "stores";
import { ModalButton, MainButton } from "components/Buttons";
import { CurrencyInput, Select } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import {
  CurrencySelectModal,
  ModalWarningInfo,
  ModalError,
} from "components/Modals";
import { AppText, Preloader, Accordion, VerificationWarning } from "components";
import { ModalRow } from "components/Rows";
import { normolizeDecimalsForInputs } from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  ItezContainer,
  TransactionGroup,
  TradeInfo,
  InfoRows,
} from "components/Modals/styled";
import { Helmet } from "react-helmet";
import { siteName } from "helpers/consts";
import { useDebounce, useScript } from "helpers/hooks";
import { callItez, getItezPrices } from "api/itez";
import {
  getPaybisCurrencyPairs,
  paybisQuote,
  initiatePaybisSession,
} from "api/paybis";
import { createWallet, createWalletAddress } from "api/wallet";
import { socket } from "api/socket";
// import { getCoinStats } from "api/coinInfo";
// import { TradesChart } from "components/Charts";
import { Container } from "../styled";
import { FIAT_OPTIONS } from "helpers/fiatOptions";
import routesPaths from "routes/routesPaths";
import type {
  AvailableTradeOptions,
  ItezFiatOption,
  WalletsListInterface,
  WalletsAddressInterface,
  PaybisBuyCryptoPairsTo,
  ItezData,
} from "helpers/types";
import { lowerThanMinAmount, higherThanMaxAmount } from "helpers/errorMessages";
import type { CreateNewAddressRes } from "api/types/wallet";
import type { ReqItezData } from "api/types/itez";
import type {
  PaybisGetBuyCryptoQuoteReq,
  PaybisInitiateSessionReq,
  PaybisFees,
} from "api/types/paybis";

type SelectType = "rightOptions" | "leftOptions";

const payVariants: SelectItemProps[] = [
  {
    key: "Paybis",
    value: "Paybis",
    label: "Paybis",
  },
  {
    key: "Itez",
    value: "Itez",
    label: "Itez (Bank Card)",
  },
];

// const coingeckoIds: {
//   [k: string]: string;
// } = {
//   BTC: "bitcoin",
//   ETH: "ethereum",
//   USDT: "tether",
//   TRX: "tron",
//   USDC: "usd-coin",
//   MATIC: "matic-network",
// };

// const blockChainVariants: SelectItemProps[] = [
//   {
//     key: "Tron (TRC20)",
//     value: "TRC20USDT",
//     label: "Tron (TRC20)",
//   },
//   {
//     key: "Ethereum (ERC20)",
//     value: "USDT",
//     label: "Ethereum (ERC20)",
//   },
//   // {
//   //   key: "Binance-Peg USDT (BEP20)",
//   //   value: "BEP20USDT",
//   //   label: "Binance-Peg USDT (BEP20)",
//   // },
// ];

// type ItezUSDtBlockchains = "TRC20USDT" | "USDT" | "BEP20USDT";

const itezFiatOptions: AvailableTradeOptions[] = FIAT_OPTIONS.map(
  ({ value }) => value
);

export const rightCurrencyOptions: AvailableTradeOptions[] = [
  "USDT",
  "ETH",
  "TRX",
  "MATIC",
  "LINK",
  "DAI",
  "BNB",
];

const BuyCryptoPage: FC = () => {
  useScript("https://pay.itez.com/static/main/share/merchant.js");
  // useScript("https://widget.sandbox.paybis.com/partner-exchange-widget.js");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currencies } = CurrenciesStore;
  const { walletsList, addWalletAddress } = WalletStore;
  const {
    setPaybisData,
    setItezData,
    widgetLoadError,
    buyCryptoPaySystem,
    setBuyCryptoPaySystem,
    buyCryptoPayMethodVariant,
    setBuyCryptoPayMethodVariant,
    buyCryptoPaybisPairsData,
    setBuyCryptoPaybisPairsData,
    buyCryptoLeftCurrency,
    buyCryptoRightCurrency,
    setBuyCryptoLeftCurrency,
    setBuyCryptoRightCurrency,
  } = ThirdPartyStore;
  const {
    user: { email, uid: usetId, id: oldUserId, level },
  } = UserStore;
  const { lang } = GlobalStore;
  const [paybisQuoteId, setPaybisQuoteId] = useState<string>("");
  const [paybisError, setPaybisError] = useState<string | null>(null);
  const [coinPrice, setCoinPrice] = useState<number>(0);
  // const [currentMarket, setCurrentMarket] = useState<{
  //   [k: string]: number;
  // } | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType>("leftOptions");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewPriceLoading, setIsNewPriceLoading] = useState<boolean>(false);
  const [isLeftValueLoading, setIsLeftValueLoading] = useState<boolean>(false);
  const [isRightValueLoading, setIsRightValueLoading] =
    useState<boolean>(false);
  const [leftCurrencyValue, setLeftCurrencyValue] = useState<string>("");
  const [rightCurrencyValue, setRightCurrencyValue] = useState<string>("");
  const [paybisLeftCurrencyValue, setPaybisLeftCurrencyValue] =
    useState<string>("");
  const [paybisRightCurrencyValue, setPaybisRightCurrencyValue] =
    useState<string>("");
  const [addNewAddressError, setAddNewAddressError] = useState<boolean>(false);
  const [fees, setFees] = useState<PaybisFees | null>(null);
  const [isNewAddressLoading, setIsNewAddressLoading] =
    useState<boolean>(false);
  // const [chosenBlockchain, setChosenBlockchain] =
  //   useState<ItezUSDtBlockchains>("TRC20USDT");

  const closeSelect = useCallback(() => setIsSelectOpen(false), []);

  const openSelect = useCallback((type: SelectType) => {
    setSelectType(type);
    setIsSelectOpen(true);
  }, []);

  // coingeckoCost
  // const cost = useMemo<number>(() => {
  //   if (currentMarket && buyCryptoLeftCurrency) {
  //     const currentCost = currentMarket[buyCryptoLeftCurrency.toLowerCase()];
  //     return currentCost || 0;
  //   }
  //   return 0;
  // }, [buyCryptoLeftCurrency, currentMarket]);

  const chosenFiat = useMemo<ItezFiatOption>(() => {
    return (
      FIAT_OPTIONS.find(({ value }) => value === buyCryptoLeftCurrency) ||
      FIAT_OPTIONS[0]
    );
  }, [buyCryptoLeftCurrency]);

  const minDeposit = useMemo<number>(() => {
    if (buyCryptoRightCurrency && currencies) {
      let currentCurrency = buyCryptoRightCurrency?.toUpperCase();

      if (currentCurrency === "USDT") {
        const usdtTrc20 = currencies.find(
          ({ networkCode, name }) =>
            name === currentCurrency && networkCode === "TRX"
        )?.minDeposit;

        return usdtTrc20 ? Number(usdtTrc20) : 0;
      }

      if (currentCurrency === "USDC") {
        if (buyCryptoPaySystem === "Itez") {
          const usdcTrc20 = currencies.find(
            ({ networkCode, name }) =>
              name === currentCurrency && networkCode === "TRX"
          )?.minDeposit;

          return usdcTrc20 ? Number(usdcTrc20) : 0;
        }
        if (buyCryptoPaySystem === "Paybis") {
          const usdcErc20 = currencies.find(
            ({ networkCode, name }) =>
              name === currentCurrency && networkCode === "ETH"
          )?.minDeposit;

          return usdcErc20 ? Number(usdcErc20) : 0;
        }
      }
      const baseMinDep = currencies.find(
        ({ name }) => name === currentCurrency
      )?.minDeposit;
      return baseMinDep ? Number(baseMinDep) : 0;
    }
    return 0;
  }, [buyCryptoRightCurrency, currencies, buyCryptoPaySystem]);

  const maxDeposit = useMemo<number>(() => {
    if (buyCryptoRightCurrency && currencies) {
      let currentCurrency = buyCryptoRightCurrency?.toUpperCase();

      if (currentCurrency === "USDT") {
        const usdtTrc20 = currencies.find(
          ({ networkCode, name }) =>
            name === currentCurrency && networkCode === "TRX"
        )?.maxDeposit;

        return usdtTrc20 ? Number(usdtTrc20) : 0;
      }

      if (currentCurrency === "USDC") {
        if (buyCryptoPaySystem === "Itez") {
          const usdcTrc20 = currencies.find(
            ({ networkCode, name }) =>
              name === currentCurrency && networkCode === "TRX"
          )?.maxDeposit;

          return usdcTrc20 ? Number(usdcTrc20) : 0;
        }
        if (buyCryptoPaySystem === "Paybis") {
          const usdcErc20 = currencies.find(
            ({ networkCode, name }) =>
              name === currentCurrency && networkCode === "ETH"
          )?.maxDeposit;

          return usdcErc20 ? Number(usdcErc20) : 0;
        }
      }
      const baseMinDep = currencies.find(
        ({ name }) => name === currentCurrency
      )?.maxDeposit;
      return baseMinDep ? Number(baseMinDep) : 0;
    }
    return 0;
  }, [buyCryptoRightCurrency, currencies, buyCryptoPaySystem]);

  const paybisCoinVariants = useMemo<PaybisBuyCryptoPairsTo[]>(() => {
    if (
      buyCryptoPaySystem !== "Paybis" ||
      !buyCryptoPaybisPairsData ||
      !currencies
    ) {
      return [];
    }
    const paybisMethodVariants = buyCryptoPaybisPairsData.find(
      ({ name }) => name === buyCryptoPayMethodVariant
    );
    if (!paybisMethodVariants) {
      return [];
    }

    const availableCurrencies = currencies.map(({ name }) => name);

    const paybisPairs = paybisMethodVariants.pairs.find(
      ({ from }) => from === buyCryptoLeftCurrency
    );
    if (!paybisPairs) {
      return [];
    }
    return paybisPairs.to.filter(
      ({ currency, currencyCode }) =>
        paybisAvailableCoins.includes(currencyCode) &&
        availableCurrencies.includes(currency)
    );
  }, [
    buyCryptoPaybisPairsData,
    currencies,
    buyCryptoLeftCurrency,
    buyCryptoPayMethodVariant,
    buyCryptoPaySystem,
  ]);

  const correctedRightOptions = useMemo<AvailableTradeOptions[]>(() => {
    if (buyCryptoPaySystem === "Itez") {
      if (
        currencies &&
        currencies.find(
          ({ networkCode, name }) => name === "USDC" && networkCode === "TRX"
        )
      ) {
        return [...rightCurrencyOptions, "USDC"];
      }
      return rightCurrencyOptions;
    }

    if (buyCryptoPaySystem === "Paybis" && paybisCoinVariants.length > 0) {
      const coinOptions = paybisCoinVariants.map(({ currency }) => currency);

      return coinOptions || [];
    }
    return [];
  }, [currencies, buyCryptoPaySystem, paybisCoinVariants]);

  const hasError = useMemo<string | null>(() => {
    if (buyCryptoPaySystem === "Itez" && !leftCurrencyValue) {
      return null;
    }
    if (
      buyCryptoPaySystem === "Paybis" &&
      !rightCurrencyValue &&
      !paybisRightCurrencyValue
    ) {
      return null;
    }

    if (
      buyCryptoPaySystem === "Itez" &&
      Number(
        normolizeDecimalsForInputs(
          leftCurrencyValue,
          buyCryptoLeftCurrency!,
          currencies
        )
      ) < chosenFiat.min
    ) {
      return t(lowerThanMinAmount);
    }

    if (
      buyCryptoPaySystem === "Itez" &&
      Number(
        normolizeDecimalsForInputs(
          leftCurrencyValue,
          buyCryptoLeftCurrency!,
          currencies
        )
      ) > chosenFiat.max
    ) {
      return t(higherThanMaxAmount);
    }

    if (
      buyCryptoPaySystem === "Paybis" &&
      minDeposit > 0 &&
      ((paybisRightCurrencyValue &&
        Number(paybisRightCurrencyValue) < minDeposit) ||
        (!paybisRightCurrencyValue &&
          rightCurrencyValue &&
          Number(rightCurrencyValue) < minDeposit))
    ) {
      return t(lowerThanMinAmount);
    }

    if (
      buyCryptoPaySystem === "Paybis" &&
      maxDeposit > 0 &&
      ((paybisRightCurrencyValue &&
        Number(paybisRightCurrencyValue) > minDeposit) ||
        (!paybisRightCurrencyValue &&
          rightCurrencyValue &&
          Number(rightCurrencyValue) > maxDeposit))
    ) {
      return t(higherThanMaxAmount);
    }

    return null;
  }, [
    buyCryptoLeftCurrency,
    buyCryptoPaySystem,
    chosenFiat,
    currencies,
    leftCurrencyValue,
    maxDeposit,
    minDeposit,
    rightCurrencyValue,
    paybisRightCurrencyValue,
    t,
  ]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      (!leftCurrencyValue && !paybisLeftCurrencyValue) ||
      !buyCryptoLeftCurrency ||
      !walletAddress
    ) {
      return;
    }

    let toCurrency = buyCryptoRightCurrency?.toUpperCase() || "USDT";

    if (buyCryptoPaySystem === "Itez") {
      if (toCurrency === "USDT") {
        toCurrency = "TRC20USDT";
      }
      if (toCurrency === "USDC") {
        toCurrency = "TRC20USDC";
      }
      if (toCurrency === "LINK") {
        toCurrency = "2E757901-21A3-40E8-95BF-887F1126E806";
      }
      if (toCurrency === "DAI") {
        toCurrency = "437102F1-80DA-475E-A033-E79A40E3E8A5";
      }

      let from_amount = String(Number(leftCurrencyValue) * 100);

      if (buyCryptoLeftCurrency === "KRW") {
        from_amount = leftCurrencyValue;
      }

      const buyCryptoData: ReqItezData = {
        target_element: "widget-itez",
        to_account: walletAddress,
        user_login: email,
        from_currency: buyCryptoLeftCurrency,
        //@ts-ignore
        to_currency: toCurrency,
        from_amount,
        lang,
      };

      setIsLoading(true);

      callItez(buyCryptoData)
        .then(({ data }) => {
          const respData: ItezData = {
            partner_token: data.key,
            ...buyCryptoData,
            signature: data.signature,
            timestamp: data.timestamp,
          };

          setItezData(respData);
          navigate(routesPaths.cryptoWidget, {
            state: { route: routesPaths.buyCrypto },
          });
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
      return;
    }

    if (buyCryptoPaySystem === "Paybis" && paybisCoinVariants.length > 0) {
      const toCurrency = paybisCoinVariants.find(
        ({ currency }) => currency === buyCryptoRightCurrency
      )?.currencyCode;

      if (!toCurrency) {
        GlobalStore.setError("toCurrency Error");
      }

      const buyCryptoData: PaybisInitiateSessionReq = {
        partnerUserId: usetId ? String(usetId) : String(oldUserId),
        email,
        quoteId: paybisQuoteId,
        cryptoWalletAddress: {
          address: walletAddress,
          currencyCode: toCurrency!,
        },
        locale: lang,
        paymentMethod: buyCryptoPayMethodVariant,
        flow: "buyCrypto",
      };

      setIsLoading(true);

      initiatePaybisSession(buyCryptoData)
        .then(({ data }) => {
          if (data.errors && data.errors.length > 0) {
            GlobalStore.setError(data.errors[0].message);
            return;
          }
          if (data.requestId) {
            setPaybisData(data.requestId);
            setFees(null);
            setPaybisLeftCurrencyValue("");
            setPaybisRightCurrencyValue("");
            setRightCurrencyValue("");
            setLeftCurrencyValue("");
            navigate(routesPaths.cryptoWidget, {
              state: { route: routesPaths.buyCrypto },
            });
          }
          return;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
      return;
    }
  };

  const handleChangeValue = (value: string, isRight?: "isRight") => {
    const correctInvalidValue = value[0] === "." ? `0${value}` : value;
    setPaybisError(null);
    setFees(null);

    !isRight
      ? setLeftCurrencyValue(correctInvalidValue)
      : setRightCurrencyValue(correctInvalidValue);

    if (buyCryptoPaySystem === "Paybis") {
      setPaybisLeftCurrencyValue("");
      setPaybisRightCurrencyValue("");

      if (Number(correctInvalidValue) > 0) {
        !isRight ? setIsRightValueLoading(true) : setIsLeftValueLoading(true);
      }
      if (Number(correctInvalidValue) <= 0) {
        !isRight ? setRightCurrencyValue("") : setLeftCurrencyValue("");
      }
      return;
    }

    const newSecondBuyValue = Number(value) / coinPrice;
    const newSecondSellValue = Number(value) * coinPrice;

    if (isNaN(newSecondBuyValue) || isNaN(newSecondSellValue)) {
      setIsNewPriceLoading(false);
      return !isRight ? setRightCurrencyValue("") : setLeftCurrencyValue("");
    }

    const newLeftCurrencyValue = String(
      newSecondSellValue === 0
        ? ""
        : normolizeDecimalsForInputs(
            newSecondSellValue,
            buyCryptoLeftCurrency!,
            currencies
          )
    );

    !isRight
      ? setRightCurrencyValue(
          String(
            newSecondBuyValue === 0
              ? ""
              : buyCryptoRightCurrency
              ? normolizeDecimalsForInputs(
                  newSecondBuyValue,
                  buyCryptoRightCurrency.toUpperCase(),
                  currencies
                )
              : "0"
          )
        )
      : setLeftCurrencyValue(newLeftCurrencyValue);
  };

  const onCurrencyChange = (option: AvailableTradeOptions) => {
    if (
      (selectType === "leftOptions" && option === buyCryptoLeftCurrency) ||
      (selectType !== "leftOptions" && option === buyCryptoRightCurrency)
    ) {
      return;
    }
    setAddNewAddressError(false);
    if (
      (leftCurrencyValue || rightCurrencyValue) &&
      buyCryptoPaySystem === "Itez"
    ) {
      setIsNewPriceLoading(true);
    }

    if (selectType === "leftOptions") {
      setBuyCryptoLeftCurrency(option);
      return;
    }

    setBuyCryptoRightCurrency(option);
  };

  useEffect(() => {
    if (buyCryptoPaySystem === "Itez") {
      let toCurrency = buyCryptoRightCurrency;

      if (toCurrency === "USDC") {
        toCurrency = "TRC20USDC";
      }

      if (toCurrency === "LINK") {
        toCurrency = "2E757901-21A3-40E8-95BF-887F1126E806";
      }

      if (toCurrency === "DAI") {
        toCurrency = "437102F1-80DA-475E-A033-E79A40E3E8A5";
      }

      const reqData = {
        from_currency: buyCryptoLeftCurrency,
        to_currency: toCurrency,
        // from_amount: 100,
        to_amount: buyCryptoLeftCurrency === "KRW" ? 100000000 : 1000000,
      };
      callItez({
        ...reqData,
      })
        .then(({ data }) => getItezPrices({ ...data, ...reqData }))
        .then(({ data }) =>
          setCoinPrice(
            buyCryptoRightCurrency === "USDC" ||
              buyCryptoRightCurrency === "USDT"
              ? data.from_amount / 1000000
              : data.from_amount / 100
          )
        )
        .catch((err) => {
          console.log("err", err);
          setCoinPrice(0);
        })
        .finally(() => setIsNewPriceLoading(false));
    }
  }, [
    buyCryptoLeftCurrency,
    buyCryptoRightCurrency,
    buyCryptoPayMethodVariant,
    buyCryptoPaySystem,
  ]);

  const handleChangePaybisValue = useDebounce(
    (value: string, isRight?: "isRight") => {
      if (
        buyCryptoPaySystem === "Paybis" &&
        paybisCoinVariants.length > 0 &&
        buyCryptoPayMethodVariant
      ) {
        !isRight ? setLeftCurrencyValue(value) : setRightCurrencyValue(value);

        let toCurrency = paybisCoinVariants.find(
          ({ currency }) => currency === buyCryptoRightCurrency
        )?.currencyCode;

        if (!toCurrency || Number(value) <= 0) {
          setIsLeftValueLoading(false);
          setIsRightValueLoading(false);
          isRight
            ? setPaybisLeftCurrencyValue("")
            : setPaybisRightCurrencyValue("");
          return;
        }

        const paybisReqData: PaybisGetBuyCryptoQuoteReq = {
          currencyCodeFrom: buyCryptoLeftCurrency,
          amount: value,
          currencyCodeTo: toCurrency,
          directionChange: isRight ? "to" : "from",
          isReceivedAmount: isRight ? true : false,
          paymentMethod: buyCryptoPayMethodVariant,
        };

        paybisQuote(paybisReqData)
          .then(({ data }) => {
            if (data.paymentMethods) {
              isRight
                ? setPaybisLeftCurrencyValue(
                    normolizeDecimalsForInputs(
                      data.paymentMethods[0].amountFrom.amount,
                      buyCryptoLeftCurrency,
                      currencies
                    )
                  )
                : setPaybisRightCurrencyValue(
                    normolizeDecimalsForInputs(
                      data.paymentMethods[0].amountTo.amount,
                      buyCryptoRightCurrency,
                      currencies
                    )
                  );
            }
            setPaybisQuoteId(data.id);
            if (
              data.paymentMethodErrors &&
              data.paymentMethodErrors.length > 0 &&
              data.paymentMethodErrors[0].error?.message
            ) {
              const fixedError = data.paymentMethodErrors[0].error.message
                .replace("Minimum amount is", t("MINIMUM_AMOUNT_IS"))
                .replace(
                  "Amount must be less than",
                  t("AMOUNT_MUST_BE_LESS_THAN")
                );

              setPaybisError(fixedError);
            }
            if (data.paymentMethods?.[0]?.fees) {
              setFees(data.paymentMethods?.[0]?.fees);
            }
            if (!data.paymentMethodErrors && paybisError) {
              setPaybisError(null);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setIsLeftValueLoading(false);
            setIsRightValueLoading(false);
          });
      }
    },
    500
  );

  useEffect(() => {
    if (buyCryptoPaySystem === "Paybis") {
      handleChangePaybisValue(leftCurrencyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftCurrencyValue, buyCryptoRightCurrency, buyCryptoPayMethodVariant]);

  useEffect(() => {
    if (buyCryptoPaySystem === "Paybis") {
      handleChangePaybisValue(rightCurrencyValue, "isRight");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightCurrencyValue, buyCryptoLeftCurrency]);

  useEffect(() => {
    if (buyCryptoPaySystem === "Paybis") {
      handleChangeValue(leftCurrencyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCryptoRightCurrency, buyCryptoPayMethodVariant]);

  useEffect(() => {
    if (buyCryptoPaySystem === "Paybis") {
      handleChangeValue(rightCurrencyValue, "isRight");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCryptoLeftCurrency]);

  useEffect(() => {
    if (buyCryptoPaySystem === "Itez") {
      if (selectType === "leftOptions") {
        handleChangeValue(rightCurrencyValue, "isRight");
        return;
      }
      handleChangeValue(leftCurrencyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinPrice]);

  // useEffect(() => {
  //   if (buyCryptoRightCurrency) {
  //     getCoinStats(coingeckoIds[buyCryptoRightCurrency])
  //       .then(({ data }) => setCurrentMarket(data.market_data.RATE))
  //       .catch(() => setCurrentMarket(null));
  //   }
  // }, [buyCryptoRightCurrency]);

  const currentWallet = useMemo<WalletsListInterface | undefined>(
    () =>
      walletsList?.find(
        ({ currencyName }) =>
          currencyName === buyCryptoRightCurrency?.toUpperCase()
      ),
    [walletsList, buyCryptoRightCurrency]
  );

  const payMethodVariants = useMemo<SelectItemProps[]>(() => {
    if (!buyCryptoPaybisPairsData) {
      return [];
    }
    return buyCryptoPaybisPairsData.map(({ name, displayName }) => {
      return { key: name, value: name, label: displayName };
    });
  }, [buyCryptoPaybisPairsData]);

  useEffect(() => {
    const createNewWallet = async () => {
      setIsLoading(true);
      await createWallet(buyCryptoRightCurrency!.toUpperCase());
      setIsLoading(false);
    };
    if (buyCryptoRightCurrency && walletsList && !currentWallet) {
      createNewWallet();
    }
  }, [buyCryptoRightCurrency, currentWallet, walletsList]);

  const walletAddress = useMemo<string | undefined>(() => {
    if (buyCryptoRightCurrency && currentWallet) {
      let currentCurrency = buyCryptoRightCurrency?.toUpperCase();

      // if (currentCurrency === "USDT") {
      //   if (chosenBlockchain === "TRC20USDT") {
      //     const usdtTrc20 = currentWallet.addresses.find(
      //       ({ networkCode }) => networkCode === "TRX"
      //     )?.address;

      //     return usdtTrc20 || undefined;
      //   }
      //   if (chosenBlockchain === "USDT") {
      //     const usdtErc20 = currentWallet.addresses.find(
      //       ({ networkCode }) => networkCode === "ETH"
      //     )?.address;

      //     return usdtErc20 || undefined;
      //   }
      // }

      if (currentCurrency === "USDT") {
        const usdtTrc20 = currentWallet.addresses.find(
          ({ networkCode }) => networkCode === "TRX"
        )?.address;

        return usdtTrc20 || undefined;
      }

      if (currentCurrency === "USDC") {
        if (buyCryptoPaySystem === "Itez") {
          const usdcTrc20 = currentWallet.addresses.find(
            ({ networkCode }) => networkCode === "TRX"
          )?.address;

          return usdcTrc20 || undefined;
        }
        if (buyCryptoPaySystem === "Paybis") {
          const usdcErc20 = currentWallet.addresses.find(
            ({ networkCode }) => networkCode === "ETH"
          )?.address;

          return usdcErc20 || undefined;
        }
      }
      return currentWallet.addresses[0]?.address;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsList, buyCryptoRightCurrency, currentWallet]);

  const addNewWalletAddress = useCallback(
    (data: CreateNewAddressRes) => {
      if (data.status === "REQUESTED") {
        return;
      }
      if (data.address && data.status === "SUCCESS") {
        const newWalletAddress: WalletsAddressInterface = {
          address: data.address!,
          currencyName: data.currencyName,
          memo: data.memo,
          networkCode: data.networkCode,
          networkName: data.networkName,
        };
        addWalletAddress(newWalletAddress, data.currencyName);
      }
      if (data.status === "ERROR") {
        setAddNewAddressError(true);
        GlobalStore.setError(
          "Sorry, unknown error occurred and address for this currency haven't created"
        );
      }
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
      setIsNewAddressLoading(false);
    },
    [addWalletAddress]
  );

  const createNewWalletAddress = useCallback(async () => {
    if (!currencies) {
      return;
    }

    if (buyCryptoRightCurrency && currentWallet && !walletAddress) {
      setAddNewAddressError(false);
      setIsNewAddressLoading(true);
      const currencyName = buyCryptoRightCurrency!.toUpperCase();

      let networkCode = currencies.find(
        ({ name }) => name === currencyName
      )?.networkCode;

      // if (currencyName === "USDT") {
      //   if (chosenBlockchain === "TRC20USDT") {
      //     networkCode = "TRX";
      //   }
      //   if (chosenBlockchain === "USDT") {
      //     networkCode = "ETH";
      //   }
      // }

      if (
        buyCryptoPaySystem === "Itez" &&
        (currencyName === "USDC" || currencyName === "USDT")
      ) {
        networkCode = "TRX";
      }

      if (buyCryptoPaySystem === "Paybis" && currencyName === "USDT") {
        networkCode = "TRX";
      }

      if (buyCryptoPaySystem === "Paybis" && currencyName === "USDC") {
        networkCode = "ETH";
      }

      if (!networkCode) {
        setIsNewAddressLoading(false);
        return GlobalStore.setError("Currency network not found");
      }

      socket.on("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);

      await createWalletAddress(networkCode, currencyName)
        .then(({ data }) => addNewWalletAddress(data))
        .catch((err) => {
          if (err?.response?.data?.message) {
            socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
            GlobalStore.setError(err.response.data.message);
            setIsNewAddressLoading(false);
          }
        });
    }
  }, [
    buyCryptoRightCurrency,
    currentWallet,
    walletAddress,
    currencies,
    addNewWalletAddress,
    buyCryptoPaySystem,
  ]);

  const leftCurrencyOptions = useMemo<AvailableTradeOptions[]>(() => {
    if (buyCryptoPaySystem === "Itez") {
      return itezFiatOptions;
    }

    if (
      buyCryptoPaySystem === "Paybis" &&
      buyCryptoPayMethodVariant &&
      buyCryptoPaybisPairsData
    ) {
      const paybisMethodVariants = buyCryptoPaybisPairsData.find(
        ({ name }) => name === buyCryptoPayMethodVariant
      );
      if (!paybisMethodVariants) {
        return [];
      }

      const paybisFiatVariants = paybisMethodVariants.pairs.map(
        ({ from }) => from
      );

      return paybisFiatVariants;
    }

    return [];
  }, [buyCryptoPaySystem, buyCryptoPayMethodVariant, buyCryptoPaybisPairsData]);

  useEffect(() => {
    createNewWalletAddress();
  }, [createNewWalletAddress]);

  useEffect(() => {
    return () => {
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
    };
  }, [addNewWalletAddress]);

  useEffect(() => {
    if (!buyCryptoPaybisPairsData) {
      setIsLoading(true);

      getPaybisCurrencyPairs()
        .then(({ data }) => setBuyCryptoPaybisPairsData(data.data))
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [setBuyCryptoPaybisPairsData, buyCryptoPaybisPairsData]);

  const changePaySystemVariant = useCallback(
    (value: string) => {
      setBuyCryptoPaySystem(value);
      setPaybisError(null);
      setFees(null);
      setBuyCryptoPayMethodVariant("");
      setPaybisLeftCurrencyValue("");
      setPaybisRightCurrencyValue("");
      setRightCurrencyValue("");
      setLeftCurrencyValue("");
    },
    [setBuyCryptoPaySystem, setBuyCryptoPayMethodVariant]
  );

  useEffect(() => {
    if (
      buyCryptoLeftCurrency &&
      leftCurrencyOptions.length > 0 &&
      !leftCurrencyOptions.includes(buyCryptoLeftCurrency)
    ) {
      const isEurAvailable = leftCurrencyOptions.find((curr) => curr === "EUR");
      if (isEurAvailable) {
        setBuyCryptoLeftCurrency("EUR");
        return;
      }
      const isUsdAvailable = leftCurrencyOptions.find((curr) => curr === "USD");

      if (isUsdAvailable) {
        setBuyCryptoLeftCurrency("USD");
        return;
      }
      setBuyCryptoLeftCurrency(leftCurrencyOptions[0] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCryptoPayMethodVariant, leftCurrencyOptions]);

  useEffect(() => {
    if (
      buyCryptoRightCurrency &&
      correctedRightOptions.length > 0 &&
      !correctedRightOptions.includes(buyCryptoRightCurrency)
    ) {
      const isUsdtAvailable = correctedRightOptions.find(
        (curr) => curr === "USDT"
      );
      if (isUsdtAvailable) {
        setBuyCryptoRightCurrency("USDT");
        return;
      }
      setBuyCryptoRightCurrency(correctedRightOptions[0] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCryptoPayMethodVariant, correctedRightOptions, buyCryptoLeftCurrency]);

  return (
    <>
      <Helmet>
        <title>Buy Crypto - {siteName}</title>
      </Helmet>

      <Container>
        {/* {currentMarket && (
              <TradesChart
                noMarket
                fiatMarket={currentMarket}
                fiatCurrency={buyCryptoLeftCurrency}
                coinCurrency={buyCryptoRightCurrency}
              />
            )} */}
        <ModalContent $isStatic>
          {(isLoading || isNewAddressLoading) && (
            <Preloader
              isStatic
              text={
                isNewAddressLoading
                  ? t("NEW_WALLET_ADDRESS_CREATING")
                  : undefined
              }
            />
          )}
          <ModalHeading>{t("BUY_CRYPTO")}</ModalHeading>
          {level === "BASIC" ? (
            <VerificationWarning text={t("BUY_CRYPTO_VERIFY_REQUIRED")} />
          ) : (
            <InnerContentForm onSubmit={(e) => onSubmit(e)}>
              <ItezContainer>
                <TransactionGroup>
                  <TradeInfo>
                    <AppText color={colors.gray_500}>{t("PAY_WITH")}</AppText>
                  </TradeInfo>

                  <Select
                    isStyled
                    value={buyCryptoPaySystem}
                    variants={payVariants}
                    setValue={(value) => changePaySystemVariant(value)}
                  />
                </TransactionGroup>

                {buyCryptoPaySystem === "Paybis" && (
                  <TransactionGroup>
                    <TradeInfo>
                      <AppText color={colors.gray_500}>
                        {t("PAY_METHOD")}
                      </AppText>
                    </TradeInfo>

                    <Select
                      isStyled
                      placeholder={t("CHOOSE_METHOD")}
                      value={buyCryptoPayMethodVariant}
                      variants={payMethodVariants}
                      setValue={(payVariant) =>
                        setBuyCryptoPayMethodVariant(payVariant)
                      }
                    />
                  </TransactionGroup>
                )}

                {(buyCryptoPaySystem === "Itez" ||
                  (buyCryptoPaySystem === "Paybis" &&
                    !!buyCryptoPayMethodVariant)) && (
                  <>
                    <TransactionGroup>
                      <TradeInfo>
                        <AppText color={colors.gray_500}>
                          {t("TO_SPEND")}
                        </AppText>
                      </TradeInfo>

                      <CurrencyInput
                        value={
                          paybisLeftCurrencyValue
                            ? paybisLeftCurrencyValue
                            : leftCurrencyValue
                        }
                        setValue={(value) => handleChangeValue(value)}
                        currency={buyCryptoLeftCurrency}
                        currencies={currencies}
                        onClick={() => openSelect("leftOptions")}
                        isLoading={
                          (selectType === "leftOptions" && isNewPriceLoading) ||
                          isLeftValueLoading
                        }
                        placeholder={
                          buyCryptoPaySystem === "Itez"
                            ? `min ${chosenFiat.min}`
                            : "0"
                        }
                      />

                      {buyCryptoPaySystem === "Itez" && (
                        <AppText
                          color={colors.gray_600}
                          fontWeight={400}
                          fontSize={12}
                        >
                          min {chosenFiat.min}, max {chosenFiat.max}
                        </AppText>
                      )}
                    </TransactionGroup>
                    <TransactionGroup>
                      <TradeInfo>
                        <AppText color={colors.gray_500}>
                          {t("TO_RECEIVE")}
                        </AppText>
                      </TradeInfo>

                      <CurrencyInput
                        value={
                          paybisRightCurrencyValue
                            ? paybisRightCurrencyValue
                            : rightCurrencyValue
                        }
                        setValue={(value) =>
                          handleChangeValue(value, "isRight")
                        }
                        currency={buyCryptoRightCurrency}
                        currencies={currencies}
                        onClick={() => openSelect("rightOptions")}
                        isLoading={
                          (selectType === "rightOptions" &&
                            isNewPriceLoading) ||
                          isRightValueLoading
                        }
                        placeholder={
                          buyCryptoPaySystem === "Paybis"
                            ? `min ${minDeposit}`
                            : "0"
                        }
                      />
                      {buyCryptoPaySystem === "Paybis" &&
                        (minDeposit > 0 || maxDeposit > 0) && (
                          <AppText
                            color={colors.gray_600}
                            fontWeight={400}
                            fontSize={12}
                          >
                            {minDeposit > 0 && `min ${minDeposit}`}
                            {maxDeposit > 0 && `, max ${maxDeposit}`}
                          </AppText>
                        )}
                    </TransactionGroup>
                    {/* {buyCryptoRightCurrency?.toUpperCase() === "USDT" && (
                    <TransactionGroup>
                      <TradeInfo>
                        <AppText color={colors.gray_500}>
                          {t("BLOCKCHAIN_TO_USE")}
                        </AppText>
                      </TradeInfo>

                      <Select
                        isStyled
                        value={chosenBlockchain}
                        variants={blockChainVariants}
                        setValue={setChosenBlockchain}
                      />
                    </TransactionGroup>
                  )} */}
                  </>
                )}
              </ItezContainer>
              {(buyCryptoPaySystem === "Itez" ||
                (buyCryptoPaySystem === "Paybis" &&
                  !!buyCryptoPayMethodVariant)) && (
                <>
                  {(hasError || paybisError) && (
                    <ModalError text={paybisError || hasError!} />
                  )}

                  {addNewAddressError && (
                    <ModalWarningInfo
                      text={`${t("ERROR_CREATING_ADDRESS")} ${t(
                        "ERROR_CREATING_ADDRESS_CRYPTO"
                      )}`}
                      fontSize={16}
                      button={
                        <MainButton
                          variant="contained"
                          onClick={createNewWalletAddress}
                        >
                          {t("TRY_AGAIN")}
                        </MainButton>
                      }
                    />
                  )}

                  {fees && fees.totalFee.amount && (
                    <Accordion
                      label={t("INCLUDING_FEES")}
                      subLabel={`${fees.totalFee.amount} ${fees.totalFee.currencyCode}`}
                    >
                      <InfoRows>
                        {fees.networkFee.amount && (
                          <ModalRow
                            keyText={t("NETWORK_FEE")}
                            value={`${fees.networkFee.amount} ${fees.networkFee.currencyCode}`}
                          />
                        )}

                        {fees.serviceFee.amount && (
                          <ModalRow
                            keyText={t("SERVICE_FEE")}
                            value={`${fees.serviceFee.amount} ${fees.serviceFee.currencyCode}`}
                          />
                        )}
                      </InfoRows>
                    </Accordion>
                  )}

                  {buyCryptoPaySystem === "Paybis" && widgetLoadError && (
                    <ModalError text={t("UNKNOWN_PAYBIS_ERROR")} />
                  )}

                  <ModalButton
                    disabled={
                      (Number(leftCurrencyValue) <= 0 &&
                        Number(paybisLeftCurrencyValue) <= 0) ||
                      !!hasError ||
                      !!paybisError ||
                      isLoading ||
                      !walletAddress ||
                      isNewPriceLoading ||
                      isLeftValueLoading ||
                      isRightValueLoading ||
                      (buyCryptoPaySystem === "Paybis" && widgetLoadError)
                    }
                    type="submit"
                  >
                    {t("BUY")}
                  </ModalButton>
                </>
              )}
            </InnerContentForm>
          )}

          <CurrencySelectModal
            onClose={closeSelect}
            isOpen={isSelectOpen}
            isFiat={selectType === "leftOptions"}
            fiatOptions={
              buyCryptoPaySystem === "Itez" ? FIAT_OPTIONS : undefined
            }
            selected={
              selectType === "leftOptions"
                ? buyCryptoLeftCurrency
                : buyCryptoRightCurrency
            }
            options={
              selectType === "leftOptions"
                ? leftCurrencyOptions
                : correctedRightOptions
            }
            setValue={onCurrencyChange}
          />
        </ModalContent>
      </Container>
    </>
  );
};

export default observer(BuyCryptoPage);

import axios from "axios";
import type {
  FntClaimInfoRes,
  FntClaimScheduleRes,
  ClaimFntTokenReq,
} from "./types/fntClaim";

export const getFntClaimInfo = async () =>
  axios.get<FntClaimInfoRes>("/fnt-token-claim");

export const getFntClaimSchedule = async () =>
  axios.get<FntClaimScheduleRes>(`/fnt-token-claim/schedule/list`);

export const claimFntToken = async (reqData: ClaimFntTokenReq) =>
  axios.post<boolean>("/fnt-token-claim", reqData);

import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CloseModalButton, ModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { supportUrl } from "helpers/consts";
import { ModalHeading, ModalContent, SemiContainer } from "./styled";
import { AppText, Link } from "components";

interface TermsAndConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const calendarLink = "https://calendly.com/onboarding-ts4";
const videoMeetingLink = "https://start.ondato.com/clients/gup-ch";

const SeniorVerificationModal: FC<TermsAndConditionsModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading $centered>{t("SENIOR_VERIFICATION")}</ModalHeading>

        <SemiContainer style={{ marginTop: "1rem" }}>
          <AppText>{t("SENIOR_VERIFICATION_TEXT_1")}</AppText>

          <AppText>{t("SENIOR_VERIFICATION_TEXT_2")}</AppText>

          <AppText>{t("SENIOR_VERIFICATION_TEXT_3")}</AppText>

          <AppText>
            {t("SENIOR_VERIFICATION_SCHEDULE")} ➡️{" "}
            <Link isOuterLink to={calendarLink}>
              {calendarLink}
            </Link>
          </AppText>

          <AppText>
            {t("SENIOR_VERIFICATION_LINK_1")}{" "}
            <Link isOuterLink to={videoMeetingLink}>
              {videoMeetingLink}
            </Link>{" "}
            {t("SENIOR_VERIFICATION_LINK_2")}
          </AppText>

          <AppText>{t("SENIOR_VERIFICATION_ADVISE")}</AppText>

          <AppText>
            {t("SENIOR_VERIFICATION_QUESTIONS_1")}{" "}
            <Link isOuterLink isEmail to={supportUrl}>
              {t("SENIOR_VERIFICATION_QUESTIONS_2")}
            </Link>
          </AppText>

          <ModalButton style={{ marginTop: "2rem" }} onClick={() => onClose()}>
            {t("CLOSE")}
          </ModalButton>
        </SemiContainer>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(SeniorVerificationModal);

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { ModalRow } from "components/Rows";
import {
  ModalButton,
  CloseModalButton,
  BackModalButton,
} from "components/Buttons";
import { ModalContent, InfoColumn, ModalHeading } from "../styled";
import { InfoRows } from "components/Modals/styled";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import { exceedAmount } from "helpers/errorMessages";
import { ModalError } from "components/Modals";
import { AppText } from "components";
import type { MembershipDiscountPack } from "api/types/membership";
import type { CurrencyInterface, WalletsListInterface } from "helpers/types";

interface ConfirmPurchaseStepProps {
  membershipPack: MembershipDiscountPack | null;
  onClose: () => void;
  onSubmit: () => void;
  currencies: CurrencyInterface[] | null;
  walletsList: WalletsListInterface[] | null;
}

const ConfirmPurchaseStep: FC<ConfirmPurchaseStepProps> = ({
  onClose,
  onSubmit,
  membershipPack,
  currencies,
  walletsList,
}) => {
  const { t } = useTranslation();

  const currencyBalance = useMemo<number>(() => {
    if (!membershipPack) {
      return 0;
    }
    const leftBalance = walletsList?.find(
      ({ currencyName }) => membershipPack.currencyName === currencyName
    )?.balance;
    return !leftBalance
      ? 0
      : Number(
          normolizeCurrenciesDecimals(
            leftBalance,
            membershipPack.currencyName,
            currencies
          )
        );
  }, [walletsList, membershipPack, currencies]);

  const hasError = useMemo<string | null>(() => {
    if (!membershipPack?.price) {
      return null;
    }

    if (Number(membershipPack?.price) > currencyBalance) {
      return t(exceedAmount);
    }

    return null;
  }, [membershipPack, currencyBalance, t]);

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <ModalHeading $centered>Membership pack review</ModalHeading>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onClose} />

        <InfoColumn>
          <AppText fontSize={16} fontWeight={400}>
            Your {membershipPack?.currencyName} balance:{" "}
            <b>{currencyBalance}</b>
          </AppText>
          <InfoRows>
            <ModalRow
              keyText={t("PRICE")}
              value={`${membershipPack?.price} ${membershipPack?.currencyName}`}
            />
            <ModalRow
              keyText={t("TOTAL_TO_RECEIVE")}
              value={`${normolizeCurrenciesDecimals(
                Number(membershipPack?.content.amount),
                membershipPack?.content.currencyName || "USDT",
                currencies
              )} ${membershipPack?.content.currencyName}`}
            />
          </InfoRows>

          {hasError && <ModalError text={hasError} />}

          <ModalButton disabled={!!hasError} onClick={onSubmit}>
            {t("CONFIRM")}
          </ModalButton>
        </InfoColumn>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmPurchaseStep;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { AppText } from "components";

import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";

interface CountdownProps {
  targetTime: string; // Example: "2025-10-13T00:30:00.000Z"
  onComplete?: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ targetTime, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment.utc();
      const target = moment.utc(targetTime);
      const diff = moment.duration(target.diff(now));

      if (diff.asSeconds() <= 0) {
        setTimeLeft("0s");
        onComplete?.();
        return;
      }

      const days = diff.days();
      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();

      let formatted = "";
      if (days > 0) formatted += `${days}d `;
      if (hours > 0 || days > 0) formatted += `${hours}h `;
      if (minutes > 0 || hours > 0 || days > 0) formatted += `${minutes}min `;
      formatted += `${seconds}s`;

      setTimeLeft(formatted.trim());
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [targetTime, onComplete]);

  return (
    <ClockInfo>
      <ClockIcon />
      <AppText>{timeLeft}</AppText>
    </ClockInfo>
  );
};

const ClockInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 15rem;

  & > svg {
    margin-right: 0.2rem;
  }
`;

export default Countdown;

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
// import { useTranslation } from "react-i18next";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import MainWithdrawStep from "./MainWithdrawStep";
import ConfirmWithdrawStep from "./ConfirmWithdrawStep";
import SuccessWithdrawStep from "./SuccessWithdrawStep";
import { CurrenciesStore, GlobalStore } from "stores";
import { claimFntToken } from "api/fntClaim";
import type { ModalStepsType, CurrencyInterface } from "helpers/types";
import type { FntClaimInfo, ClaimFntTokenReq } from "api/types/fntClaim";

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
  claimInfo: FntClaimInfo | null;
  getFntClaimData: () => void;
  getUserMembershipInfo: () => void;
}
const FntClaimWithdrawModal: FC<WithdrawModalProps> = ({
  isOpen,
  onClose,
  claimInfo,
  getFntClaimData,
  getUserMembershipInfo,
}) => {
  // const { t } = useTranslation();
  const { currencies } = CurrenciesStore;
  const [step, setStep] = useState<ModalStepsType>("main");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [skipConfirm, setSkipConfirm] = useState<boolean>(false);
  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  const [withdrawAddress, setWithdrawAddress] = useState<string>("");
  const [withdrawMemo, setWithdrawMemo] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [paymentType, setPaymentType] = useState<CurrencyInterface | undefined>(
    undefined
  );

  const hasMemo = useMemo<boolean>(() => {
    if (chosenNetwork && paymentType?.useMemo) {
      return true;
    }
    return false;
  }, [chosenNetwork, paymentType]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === "main" && !skipConfirm) {
      setStep("confirm");
      return;
    }

    const withdrawData: ClaimFntTokenReq = {
      toAddress: withdrawAddress.replaceAll(" ", ""),
      oneTimePassword: twoFACode,
    };

    if (hasMemo && withdrawMemo) {
      withdrawData.toMemo = withdrawMemo;
    }

    setIsLoading(true);

    claimFntToken(withdrawData)
      .then(() => {
        getFntClaimData();
        getUserMembershipInfo();
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isOpen && claimInfo) {
      setPaymentType(
        currencies?.find(({ name }) => name === claimInfo.currencyName)!
      );
      setChosenNetwork(claimInfo.networkCode);
    }
  }, [claimInfo, currencies, isOpen]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    setStep("main");
    setChosenNetwork("");
    setWithdrawAddress("");
    setWithdrawMemo("");
    setTwoFACode("");
    setPaymentType(undefined);
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title="Token Claim"
    >
      {isLoading && <Preloader isStatic />}

      {step === "main" && (
        <MainWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          onClose={closeModal}
          paymentType={paymentType}
          claimInfo={claimInfo}
          currencies={currencies}
          chosenNetwork={chosenNetwork}
          setWithdrawAddress={setWithdrawAddress}
          withdrawAddress={withdrawAddress}
          setIsLoading={setIsLoading}
          setTwoFACode={setTwoFACode}
          withdrawMemo={withdrawMemo}
          hasMemo={hasMemo}
          setWithdrawMemo={setWithdrawMemo}
          twoFACode={twoFACode}
          isLoading={isLoading}
        />
      )}
      {step === "confirm" && (
        <ConfirmWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          onBack={() => setStep("main")}
          onClose={closeModal}
          claimInfo={claimInfo}
          currencies={currencies}
          setTwoFACode={setTwoFACode}
          twoFACode={twoFACode}
          isLoading={isLoading}
        />
      )}
      {step === "success" && (
        <SuccessWithdrawStep
          onClose={closeModal}
          claimInfo={claimInfo}
          currencies={currencies}
        />
      )}
    </BasicModal>
  );
};

export default observer(FntClaimWithdrawModal);

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import { ModalRow } from "components/Rows";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  InfoColumn,
} from "../styled";
import { InfoRows } from "components/Modals/styled";
import { normolizeCurrenciesDecimals } from "helpers/funcs";
import type { CurrencyInterface } from "helpers/types";
import type { MembershipDiscountPack } from "api/types/membership";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import boughtImg from "assets/images/bought_success.jpg";

interface SuccessPurchaseStepProps {
  membershipPack: MembershipDiscountPack | null;
  onClose: () => void;
  currencies: CurrencyInterface[] | null;
}

const SuccessPurchaseStep: FC<SuccessPurchaseStepProps> = ({
  membershipPack,
  onClose,
  currencies,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={boughtImg} alt="decorative" />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <InfoColumn>
          <AppText
            fontSize={16}
            fontWeight={700}
            color={colors.gray_600}
            lineHeight={30}
          >
            Membership pack purchase was successful
          </AppText>
          <InfoRows>
            <ModalRow
              keyText={t("PRICE")}
              value={`${membershipPack?.price} ${membershipPack?.currencyName}`}
            />
            <ModalRow
              keyText={t("YOU_RECEIVED")}
              value={`${normolizeCurrenciesDecimals(
                Number(membershipPack?.content.amount),
                membershipPack?.content.currencyName || "USDT",
                currencies
              )} ${membershipPack?.content.currencyName}`}
            />
          </InfoRows>
          <ModalButton onClick={onClose}>{t("CONTINUE")}</ModalButton>
        </InfoColumn>
      </StatusContent>
    </Fade>
  );
};

export default SuccessPurchaseStep;

import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { InfoRows } from "../styled";
import { CurrenciesStore } from "stores";
import type { FntClaimInfo } from "api/types/fntClaim";

interface WithdrawInfoRowsProps {
  claimInfo: FntClaimInfo | null;
}

const WithdrawInfoRows: FC<WithdrawInfoRowsProps> = ({ claimInfo }) => {
  const { t } = useTranslation();
  const { currencies, prices } = CurrenciesStore;

  const mainCostValue = useMemo<number>(() => {
    const currentPrices = prices?.[`${claimInfo?.currencyName}/USD`];
    const mainCost = currentPrices?.close;
    return Number(mainCost) || 0;
  }, [prices, claimInfo]);

  return (
    <Accordion
      label={t("TOTAL")}
      subLabel={toLocaleStringWithCurrency(
        claimInfo && Number(claimInfo.amount) > 0
          ? Number(claimInfo.amount) -
              (claimInfo ? Number(claimInfo.feeAmount) : 0)
          : 0,
        claimInfo?.currencyName || "USDT",
        currencies
      )}
    >
      <InfoRows>
        <ModalRow
          keyText={t("RATE")}
          value={toLocaleStringWithCurrency(mainCostValue, "USD")}
          isBold
        />

        <ModalRow
          keyText={t("AMOUNT")}
          value={toLocaleStringWithCurrency(
            claimInfo && Number(claimInfo.amount) > 0 ? claimInfo.amount : 0,
            claimInfo?.currencyName || "USDT",
            currencies
          )}
        />

        <ModalRow
          keyText={`${t("VALUE")} USD`}
          value={toLocaleStringWithCurrency(
            claimInfo
              ? Number(claimInfo.amount) * (Number(mainCostValue) || 0)
              : 0,
            "USD",
            currencies
          )}
        />

        {claimInfo && (
          <ModalRow
            keyText={t("FIXED_FEE")}
            value={toLocaleStringWithCurrency(
              claimInfo.feeAmount,
              claimInfo.feeCurrencyName || "USDT",
              currencies
            )}
          />
        )}
      </InfoRows>
    </Accordion>
  );
};

export default observer(WithdrawInfoRows);

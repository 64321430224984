import { FC, Dispatch, SetStateAction, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import { AppText, Checkbox } from "components";
import { TextInput } from "components/Inputs";
import {
  toLocaleStringWithCurrency,
  normolizeDecimalsWithNoCode,
} from "helpers/funcs";
import WithdrawInfoRows from "./WithdrawInfoRows";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  ConfirmBlock,
  InputContainer,
} from "../styled";
import type { CurrencyInterface } from "helpers/types";
import type { FntClaimInfo } from "api/types/fntClaim";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface ConfirmWithdrawStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onBack: () => void;
  onClose: () => void;
  claimInfo: FntClaimInfo | null;
  currencies: CurrencyInterface[] | null;
  twoFACode: string;
  setTwoFACode: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const ConfirmWithdrawStep: FC<ConfirmWithdrawStepProps> = ({
  onSubmit,
  skipConfirm,
  setSkipConfirm,
  onBack,
  onClose,
  claimInfo,
  currencies,
  twoFACode,
  setTwoFACode,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onBack} />
        <ModalHeading $centered>Token Claim Review</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <ConfirmBlock>
            {claimInfo && currencyIcons[claimInfo.currencyName]
              ? currencyIcons[claimInfo.currencyName]
              : currencyIcons.not_found}
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {claimInfo?.currencyName}
            </AppText>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {normolizeDecimalsWithNoCode(
                claimInfo ? Number(claimInfo.amount) : 0,
                claimInfo?.currencyName || "USDT",
                currencies
              )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {" "}
                {claimInfo?.currencyName}
              </AppText>
            </AppText>
          </ConfirmBlock>

          <WithdrawInfoRows claimInfo={claimInfo} />

          <Checkbox
            checked={skipConfirm}
            onClick={() => setSkipConfirm(!skipConfirm)}
            label={t("DONT_SHOW_AGAIN")}
            variant="info"
          />

          <InputContainer $marginTop>
            <AppText>{t("2FA Code")}</AppText>
            <TextInput
              placeholder={t("CODE")}
              value={twoFACode}
              onChange={({ target: { value } }) => setTwoFACode(value)}
              leftIcon={<LockIcon />}
              autoComplete="off"
              name="2fa-code"
            />
          </InputContainer>

          <ModalButton type="submit" disabled={isLoading}>
            {t("CONFIRM")}{" "}
            {toLocaleStringWithCurrency(
              claimInfo ? Number(claimInfo.amount) : 0,
              claimInfo?.currencyName || "USDT",
              currencies
            )}{" "}
            {claimInfo?.feeAmount
              ? `- ${claimInfo.feeAmount} ${
                  claimInfo.feeCurrencyName || "USDT"
                } Fees`
              : ""}
          </ModalButton>
        </InnerContentForm>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmWithdrawStep;

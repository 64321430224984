import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation, Trans } from "react-i18next";
import { Fade } from "@mui/material";
import { CurrenciesStore, UserStore, GlobalStore } from "stores";
import { ModalButton, CloseModalButton, MainButton } from "components/Buttons";
import { TwoFaModal, ModalWarningInfo } from "components/Modals";
import { CurrencyInput, StyledTextInput, TextInput } from "components/Inputs";
import WithdrawInfoRows from "./WithdrawInfoRows";
import { AppText, Checkbox, Warning, VerificationWarning } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { add2FAVerification } from "api/user";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  MainContainer,
  TransactionGroup,
  SemiContainer,
  InputContainer,
} from "../styled";
import type { CurrencyInterface } from "helpers/types";
import type { FntClaimInfo } from "api/types/fntClaim";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface MainWithdrawStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onClose: () => void;
  paymentType?: CurrencyInterface;
  claimInfo: FntClaimInfo | null;
  currencies: CurrencyInterface[] | null;
  chosenNetwork: string;
  withdrawAddress: string;
  setWithdrawAddress: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  twoFACode: string;
  setTwoFACode: Dispatch<SetStateAction<string>>;
  withdrawMemo: string;
  setWithdrawMemo: Dispatch<SetStateAction<string>>;
  hasMemo: boolean;
  isLoading: boolean;
}

const MainWithdrawStep: FC<MainWithdrawStepProps> = ({
  onSubmit,
  onClose,
  paymentType,
  skipConfirm,
  setSkipConfirm,
  claimInfo,
  chosenNetwork,
  withdrawAddress,
  setWithdrawAddress,
  setIsLoading,
  twoFACode,
  setTwoFACode,
  withdrawMemo,
  setWithdrawMemo,
  hasMemo,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { currencies } = CurrenciesStore;
  const {
    user: { is2FAEnabled, level },
  } = UserStore;
  const [isWarnConfirmed, setIsWarnConfirmed] = useState<boolean>(false);
  const [twoFaUrl, setTwoFaUrl] = useState<string>("");
  const [isTwoFaOpen, setIsTwoFaOpen] = useState<boolean>(false);

  const change2Fa = useCallback(() => {
    if (is2FAEnabled) {
      setIsTwoFaOpen(true);
      return;
    }
    setIsLoading(true);
    add2FAVerification()
      .then(({ data }) => {
        setTwoFaUrl(data.url);
        setIsTwoFaOpen(true);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [is2FAEnabled, setIsLoading]);

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <ModalHeading>Token Claim</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          {level === "BASIC" || level === "JUNIOR" ? (
            <VerificationWarning text={t("WITHDRAW_VERIFY_REQUIRED")} />
          ) : (
            <>
              {paymentType?.name === "EUR" && level !== "SENIOR" ? (
                <VerificationWarning
                  text={`${paymentType.name} ${t(
                    "WITHDRAW_FIAT_VERIFY_REQUIRED"
                  )}`}
                />
              ) : (
                <>
                  <>
                    {chosenNetwork && paymentType?.networkName && (
                      <ModalWarningInfo
                        text={
                          <Trans
                            i18nKey="WITHDRAW_WARN"
                            values={{
                              currency: paymentType?.name,
                              chainType: paymentType?.networkName,
                            }}
                          />
                        }
                        subText={t("WITHDRAW_WARN_SUBTEXT")}
                      />
                    )}

                    <MainContainer $customMargin={12}>
                      <StyledTextInput
                        value={withdrawAddress}
                        setValue={(value) => setWithdrawAddress(value)}
                        leftAdornment={<AppText>Address</AppText>}
                      />

                      {hasMemo && (
                        <StyledTextInput
                          value={withdrawMemo}
                          setValue={(value) => setWithdrawMemo(value)}
                          leftAdornment={<AppText>Memo</AppText>}
                        />
                      )}

                      <TransactionGroup>
                        <CurrencyInput
                          value={claimInfo?.amount || "0"}
                          currency={claimInfo?.currencyName}
                          currencies={currencies}
                          placeholder="0"
                          noSelect
                          style={{ pointerEvents: "none" }}
                        />
                      </TransactionGroup>
                    </MainContainer>
                  </>

                  <SemiContainer>
                    <WithdrawInfoRows claimInfo={claimInfo} />

                    <Checkbox
                      checked={skipConfirm}
                      onClick={() => setSkipConfirm(!skipConfirm)}
                      label={t("WITHOUT_CONFIMATION")}
                      variant="info"
                    />

                    <Checkbox
                      checked={isWarnConfirmed}
                      onClick={() => setIsWarnConfirmed(!isWarnConfirmed)}
                      label={t("PROCEDURE_WARN")}
                      variant="warn"
                    />

                    {!is2FAEnabled && (
                      <Warning
                        text={t("TWO_FA_FOR_WITHDRAW")}
                        extraNode={
                          <MainButton
                            maxWidth="14rem"
                            color="secondary"
                            onClick={() => change2Fa()}
                          >
                            <LockIcon />
                            {t("ENABLE")}
                          </MainButton>
                        }
                      />
                    )}

                    {skipConfirm && (
                      <InputContainer $marginTop>
                        <AppText>{t("2FA Code")}</AppText>
                        <TextInput
                          placeholder={t("CODE")}
                          value={twoFACode}
                          onChange={({ target: { value } }) =>
                            setTwoFACode(value)
                          }
                          leftIcon={<LockIcon />}
                          autoComplete="off"
                          name="2fa-code"
                        />
                      </InputContainer>
                    )}

                    <ModalButton
                      disabled={
                        (claimInfo && Number(claimInfo?.amount) <= 0) ||
                        !isWarnConfirmed ||
                        !chosenNetwork ||
                        !is2FAEnabled ||
                        isLoading ||
                        withdrawAddress.length < 4 ||
                        (hasMemo && withdrawMemo.length < 4) ||
                        (skipConfirm && !twoFACode)
                      }
                      type="submit"
                    >
                      {t("WITHDRAW")}{" "}
                      {claimInfo &&
                        Number(claimInfo.amount) > 0 &&
                        toLocaleStringWithCurrency(
                          claimInfo && Number(claimInfo.amount),
                          claimInfo?.currencyName || "USDT",
                          currencies
                        )}{" "}
                      {claimInfo &&
                      Number(claimInfo.amount) > 0 &&
                      claimInfo?.feeAmount
                        ? `- ${claimInfo.feeAmount} ${
                            claimInfo.feeCurrencyName || "USDT"
                          } Fees`
                        : ""}
                    </ModalButton>
                  </SemiContainer>
                </>
              )}
            </>
          )}
        </InnerContentForm>

        <TwoFaModal
          isOpen={isTwoFaOpen}
          url={twoFaUrl}
          onClose={() => setIsTwoFaOpen(false)}
        />
      </ModalContent>
    </Fade>
  );
};

export default observer(MainWithdrawStep);

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import {
  toLocaleStringWithCurrency,
  normolizeCurrenciesDecimals,
  convertCurrencyValue,
} from "helpers/funcs";
import { InfoRows } from "components/Modals/styled";
import type {
  AvailableTradeOptions,
  CurrencyInterface,
  FeesInterface,
  PricesInterface,
} from "helpers/types";

interface TradeInfoRowsProps {
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  productCurrency?: string | null;
  fees?: FeesInterface | null;
  cost: number;
  isFeesInUsdt?: boolean;
  prices?: PricesInterface | null;
  usdCost?: number;
  currencies: CurrencyInterface[] | null;
  isCrypto?: boolean;
  userCard?: boolean;
  isWithdraw?: boolean;
  isTopUp?: boolean;
  currentFee?: string;
  isSuccess?: boolean;
  eurValue?: string;
}

const BuyInfoRows: FC<TradeInfoRowsProps> = ({
  currencyValue,
  currency,
  productCurrency,
  fees,
  cost,
  isFeesInUsdt,
  prices,
  usdCost,
  currencies,
  isCrypto,
  userCard,
  isWithdraw,
  isTopUp,
  currentFee,
  isSuccess,
  eurValue,
}) => {
  const { t } = useTranslation();

  return (
    <InfoRows>
      <ModalRow
        keyText={t("RATE")}
        value={`${cost > 0 ? "≈ " : ""}${toLocaleStringWithCurrency(
          cost,
          productCurrency || "USD"
        )}`}
      />
      {!currentFee && (
        <ModalRow
          keyText={t("YOU_SPEND")}
          value={`${normolizeCurrenciesDecimals(
            userCard
              ? Number(currencyValue) || 0
              : (Number(currencyValue) || 0) / Number(cost),
            isWithdraw ? productCurrency || "USD" : currency || "USD",
            currencies,
            "forView"
          )} ${isWithdraw ? productCurrency || "" : currency || ""}`}
        />
      )}
      {isSuccess && (
        <ModalRow
          keyText={t("YOU_SPENT")}
          value={`${normolizeCurrenciesDecimals(
            userCard
              ? eurValue
                ? eurValue
                : Number(currencyValue) || 0
              : (Number(currencyValue) || 0) / Number(cost),
            isWithdraw ? productCurrency || "USD" : currency || "USD",
            currencies,
            "forView"
          )} ${isWithdraw ? productCurrency || "" : currency || ""}`}
        />
      )}

      {!isCrypto && fees && (
        <ModalRow
          keyText={t("FEES")}
          value={`${normolizeCurrenciesDecimals(
            isTopUp || isWithdraw
              ? (Number(currencyValue) || 0) *
                  (fees ? Number(fees.value.feePercent) : 0 || 0)
              : ((Number(currencyValue) || 0) *
                  (fees ? Number(fees.value.feePercent) : 0 || 0)) /
                  Number(cost),
            isWithdraw ? productCurrency || "USD" : currency || "USD",
            currencies,
            "forView"
          )} ${isWithdraw ? productCurrency || "USD" : currency || "USD"}`}
        />
      )}
      {currentFee && (
        <ModalRow
          keyText={t("INCLUDING_FEES")}
          value={`${normolizeCurrenciesDecimals(
            currentFee,
            isWithdraw ? productCurrency || "USD" : currency || "USD",
            currencies,
            "forView"
          )} ${isWithdraw ? productCurrency || "USD" : currency || "USD"}`}
        />
      )}

      {!isWithdraw && !isTopUp && fees && (
        <ModalRow
          keyText={t("FIXED_FEE")}
          value={`${normolizeCurrenciesDecimals(
            isFeesInUsdt && currency && prices
              ? convertCurrencyValue(
                  "USDT",
                  isWithdraw ? productCurrency || "USD" : currency || "USD",
                  Number(fees.value.fixedUSDTFee) || 0,
                  prices
                )
              : usdCost
              ? (Number(fees.value.fixedUSDTFee) || 0) / Number(usdCost)
              : 0,
            isWithdraw ? productCurrency || "USD" : currency || "USD",
            currencies,
            "forView"
          )} ${isWithdraw ? productCurrency || "USD" : currency || "USD"}`}
        />
      )}
    </InfoRows>
  );
};

export default BuyInfoRows;

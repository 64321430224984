import { FC, useMemo, useState, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Inputs";
import { CoinCell } from "components/Table";
import { CloseModalButton, BackModalButton } from "components/Buttons";
import { AppText } from "components";
import BasicModal from "components/Modals/BasicModal";
import { colors } from "helpers/consts";
import { WalletStore, CurrenciesStore } from "stores";
import {
  TopSemiContent,
  ModalHeading,
  CurrencyOptions,
  CurrencyOption,
  CurrencyLine,
  BeingSelected,
  CurrencyColumn,
  SemiContent,
  AssetsChooseInfo,
} from "components/Modals/styled";
import {
  normolizeDecimalsWithNoCode,
  getCurrenciesPriority,
} from "helpers/funcs";
import type { AvailableTradeOptions, ItezFiatOption } from "helpers/types";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

interface CurrencySelectOptions {
  currency: AvailableTradeOptions;
  icon?: ReactNode;
}
interface CurrencySelectProps {
  isOpen: boolean;
  onClose: () => void;
  selected: AvailableTradeOptions | null;
  options: Array<AvailableTradeOptions>;
  setValue: (option: AvailableTradeOptions) => void;
  isInsideModal?: boolean;
  isFiat?: boolean;
  fiatOptions?: Array<ItezFiatOption>;
}
const CurrencySelect: FC<CurrencySelectProps> = ({
  isOpen,
  onClose,
  options,
  selected,
  setValue,
  isInsideModal,
  isFiat,
  fiatOptions,
}) => {
  const { t } = useTranslation();
  const { walletsList } = WalletStore;
  const { currencies, prices } = CurrenciesStore;
  const [search, setSearch] = useState<string>("");
  const [wasFocused, setWasFocused] = useState<boolean>(false);

  const onSelect = (currency: AvailableTradeOptions) => {
    setValue(currency);
    onClose();
  };

  const optionBalances = useMemo<{ [k: string]: string }>(() => {
    if (!walletsList) {
      return {};
    }
    return walletsList.reduce((acc: { [k: string]: string }, el) => {
      acc[el.currencyName] = normolizeDecimalsWithNoCode(
        String(el.balance),
        el.currencyName,
        currencies,
        "withNoSymbols"
      );
      return acc;
    }, {});
  }, [walletsList, currencies]);

  const searchedOptions = useMemo<CurrencySelectOptions[]>(() => {
    if (isFiat && fiatOptions) {
      return fiatOptions
        .filter(({ value }) =>
          value.toLowerCase().includes(search.toLowerCase())
        )
        .map(({ value, icon }) => {
          return { currency: value, icon };
        });
    }

    if (currencies && prices) {
      const currenciesPriority = getCurrenciesPriority(currencies);

      return [...options]
        .filter((value) => value.toLowerCase().includes(search.toLowerCase()))
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort((a, b) => currenciesPriority[a] - currenciesPriority[b])
        .sort((a, b) => {
          const coinAValue =
            Number(optionBalances[a.toUpperCase()]?.replaceAll(",", "")) ||
            0 *
              (a.toUpperCase() === "USD"
                ? 1
                : Number(prices?.[`${a.toUpperCase()}/USD`]?.close) || 0);
          const coinBValue =
            Number(optionBalances[b.toUpperCase()]?.replaceAll(",", "")) ||
            0 *
              (b.toUpperCase() === "USD"
                ? 1
                : Number(prices?.[`${b.toUpperCase()}/USD`]?.close) || 0);

          return coinBValue - coinAValue;
        })
        .map((value) => {
          return { currency: value };
        });
    }

    if (currencies) {
      const currenciesPriority = getCurrenciesPriority(currencies);

      return [...options]
        .filter((value) => value.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => currenciesPriority[a] - currenciesPriority[b])
        .map((value) => {
          return { currency: value };
        });
    }

    return options
      .filter((value) => value.toLowerCase().includes(search.toLowerCase()))
      .map((value) => {
        return { currency: value };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, search, fiatOptions, isFiat, currencies, optionBalances]);

  const setFocus = useCallback((ref: HTMLInputElement) => {
    setWasFocused(true);
    ref.focus();
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      isInsideModal={isInsideModal}
      onExited={() => {
        setSearch("");
        setWasFocused(false);
      }}
    >
      <SemiContent>
        {isInsideModal ? (
          <BackModalButton onBack={onClose} />
        ) : (
          <CloseModalButton onClose={onClose} label={t("CLOSE_SELECT")} />
        )}

        <TopSemiContent $withInfo>
          <ModalHeading $centered>{t("SELECT_ASSET")}</ModalHeading>
          <TextInput
            value={search}
            inputRef={(input) =>
              isOpen && input && !wasFocused && setFocus(input)
            }
            onChange={({ target: { value } }) => setSearch(value)}
            leftIcon={<SearchIcon />}
            placeholder={t("SEARCH")}
          />
          <AssetsChooseInfo>
            <AppText fontSize={15} color={colors.gray_600}>
              {t("ASSET")}
            </AppText>
            {!isFiat && (
              <AppText fontSize={15} color={colors.gray_600}>
                {t("BALANCE")}
              </AppText>
            )}
          </AssetsChooseInfo>
        </TopSemiContent>
        <CurrencyOptions>
          {searchedOptions.map(({ currency, icon }) => (
            <CurrencyOption
              key={currency}
              onClick={() => onSelect(currency)}
              $isSelected={currency === selected}
            >
              <CurrencyLine>
                <CoinCell
                  text={currency.toUpperCase()}
                  coin={currency.toUpperCase()}
                  icon={icon}
                />

                {!isFiat && (
                  <CurrencyColumn>
                    <AppText color={colors.gray_700}>
                      {optionBalances[currency.toUpperCase()] || "0"}{" "}
                      {currency.toUpperCase()}
                    </AppText>
                    {/* <AppText
                      fontWeight={400}
                      color={
                        currency.change24h < 0
                          ? colors.error_500
                          : colors.success_500
                      }
                    >
                      {currency.change24h < 0 ? "" : "+"}
                      {currency.change24h}%
                    </AppText> */}
                  </CurrencyColumn>
                )}
              </CurrencyLine>
              <BeingSelected>
                {currency === selected && <CheckIcon />}
              </BeingSelected>
            </CurrencyOption>
          ))}
        </CurrencyOptions>
      </SemiContent>
    </BasicModal>
  );
};

export default CurrencySelect;

import { FC, ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { colors } from "helpers/consts";
import { AppText } from "components";

interface WarningInfoProps {
  text: string | ReactNode;
  component?: ReactNode;
  subText?: string;
  padding?: string;
  style?: CSSProperties;
  fontSize?: number;
  button?: ReactNode;
}

const ModalWarningInfo: FC<WarningInfoProps> = ({
  text,
  subText,
  component,
  padding,
  style,
  fontSize,
  button,
}) => {
  return (
    <WarningConrainer $padding={padding} style={style} $withButton={!!button}>
      {component && component}
      <AppText
        fontSize={fontSize ? fontSize : 12}
        fontWeight={600}
        color={colors.gray_600}
        style={{
          marginTop: component ? "0.4rem" : "0",
          padding: component ? "0 0.4rem" : "0",
        }}
      >
        {text}
      </AppText>
      {subText && (
        <AppText isSpan fontSize={12} fontWeight={400} color={colors.gray_600}>
          {subText}
        </AppText>
      )}
      {button && button}
    </WarningConrainer>
  );
};

interface WarningConrainerInterface {
  $padding?: string;
  $withButton?: boolean;
}
const WarningConrainer = styled.div<WarningConrainerInterface>`
  width: 100%;
  display: flex;
  align-items: ${({ $withButton }) => ($withButton ? "center" : "flex-start")};
  flex-direction: column;
  padding: ${({ $padding }) => ($padding ? `${$padding}` : "0.8rem 1.2rem")};
  background-color: ${colors.error_50};
  border: 1px solid ${colors.error_200};
  border-radius: 0.8rem;
  ${({ $withButton }) =>
    $withButton &&
    `& > button {
    margin-top: 1rem;
  }`}
`;

export default ModalWarningInfo;
